const Q = require(`q`);
const _ = require(`underscore`);
const __request = require(`./__request`);

// 接口文档：
// xxx
const web_credit_creditAccountDetail = (pParameter) => {
    if (!pParameter) pParameter = {};
    const creditMode = pParameter.creditMode || 1;

    const p = {
        method: 'post',
        urlSuffix: '/web/credit/creditAccountDetail',
        data: {
            creditMode,
        },
    };
    return Q.when()
        .then(function () {
            return __request(p);
        })
        .then(function (data) {
            return Q.resolve(data);
        })
        .catch(function (ex) {
            console.error(ex);
            if (ex) {
                if (ex.stack) {
                    console.error(ex.stack);
                }
            }
            return Q.reject(ex);
        })
        ;

};
module.exports = web_credit_creditAccountDetail;
