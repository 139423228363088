// 押金情况  接口文档地址：   http://219.128.52.2:18001/doc.html#/credit/credit-account-controller/depositFlowListUsingPOST

const Q = require("q");
const __request = require(`./__request/__request_contentType_json`);

const web_credit_depositFlowList = function(pParameter) {
  if (!pParameter) pParameter = {};

  const currentPage = pParameter.currentPage || 1;
  const pageSize = pParameter.pageSize || 10;

  return Q.when()
    .then(function() {
      return __request({
        urlSuffix: "/web/credit/depositFlowList",
        data: {
          currentPage,
          pageSize,
        },
      });
    })
    .catch(function(ex) {
      console.error(ex);
      if (ex) {
        if (ex.stack) {
          console.error(ex.stack);
        }
      }
      return Q.reject(ex);
    });
};

module.exports = web_credit_depositFlowList;
