// 授信账户详情
import web_credit_creditAccountDetail from "@/lib/data-service/default/web_credit_creditAccountDetail";
// 授信账户详情
import web_credit_depositFlowList from "@/lib/data-service/default/web_credit_depositFlowList";

export default {
    data() {
        return {
            // 授信账户信息
            detail: {},
            loading: false,
            // 最近流水
            flowList: [],

            // 押金情况显示
            guaranteeVisible: false,
            // 押金情况显示请求参数
            form: {
                pageSize: 10,
                currentPage: 1,
            },
            // 押金翻页按钮参数
            pager: {
                total: 0,
                pageSizes: [10, 15, 20, 25, 30],
            },
            // 押金数据
            depositList: [],
            depositDetail: {
                cashDepositAmount: 0,
                depositBalance: 0,
            },
            depositLoading: false,
        };
    },
    filters: {},
    methods: {
        // 授信账户信息
        req_detail() {
            this.loading = true;
            web_credit_creditAccountDetail({
                creditMode: 2,
            }).then((result) => {
                this.detail = result;
                this.flowList = result.creditFlowResults.pageData;
                this.loading = false;
            }).catch((err) => {
                this.loading = false;
            });
        },
        // 押金情况请求
        req_deposit_list() {
            this.depositLoading = true;
            web_credit_depositFlowList(this.form)
                .then((result) => {
                    const datas = result.pageResult;
                    const depositDetail = this.depositDetail;
                    depositDetail.cashDepositAmount = result.cashDepositAmount;
                    depositDetail.depositBalance = result.depositBalance;
                    this.pager.total = datas.totalCount;
                    this.depositList = datas.pageData;
                    this.depositLoading = false;
                })
                .catch((err) => {
                    this.depositLoading = false;
                });
        },

        to_bill() {
            this.$router.push({
                name: "distributor-easy-pay-easy-pay-repayment",
            });
        },

        oneKey_repayment() {
            this.$router.push({
                name: "distributor-credit-back-payment",
                query: {
                    type: 1,
                },
            });
        },
        open_guarantee() {
            this.guaranteeVisible = true;
            this.req_deposit_list();
        },
        close_guarantee() {
            this.guaranteeVisible = false;
            this.depositList = [];
            this.form.currentPage = 1;
        },

        change_currentPage(currentPage) {
            this.form.currentPage = currentPage;
            this.req_deposit_list();
        },

        change_pageSize(pageSize) {
            this.form.pageSize = pageSize;
            this.change_currentPage(1);
        },
        to_flowing() {
            const name =
                this.detail.businessAccountTypeFlag === "1+2"
                    ? "distributor-easy-pay-easy-pay-flow-flight-travel"
                    : "distributor-easy-pay-easy-pay-flow-flight";
            this.$router.push({
                name,
            });
        },

        format_status(val) {
            switch (val) {
                case 0:
                    return "await";
                    break;
                case 1:
                    return "success";
                    break;
                case 2:
                    return "warning";
                    break;
                case 3:
                    return "danger";
                    break;
            }
        },
    },
    created() {
        this.req_detail();
    },
};
